import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from "react-moment";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import $ from "jquery";
import Pagination from "react-js-pagination";
import { inclusiveRange } from "./../transaction/transaction";
import "./rewardmessage.css";
import { read_cookie } from "../../read_cookie";
import config from "./../../configs/config";
import homeData from './../../data/home.json';

const RewardMessages = (props) => {
  document.title = props.title;

  let [messages, setMessages] = useState([]);
  let [messagesidArr, setMessagesidArr] = useState([]);
  const [togglePopup, settogglePopup] = useState(false);
  const [loading, setloading] = useState(false);
  let [records, setRecords] = useState(10);
  let [recordsArr, setRecordsArr] = useState([10]);
  let [sort, setsort] = useState("down");
  let [activePage, setactivePage] = useState(1);
  let [startCount, setStartCount] = useState(0);

  const handlePageChange = (pagenum) => {
    setactivePage(pagenum);
    let stCount = pagenum * records - records;
    // let endCount = (pagenum * records)+1;
    // console.log(stCount,records,trans.slice(stCount,(stCount+records)),(stCount+records))
    setStartCount(stCount);
    $(".form-check-input").prop("checked", false);
    checkboxChange();
  };
  const FormateDate = (d) => {
    // Ej: "28-NOV-24 06:03:58 AM"
    // chicago
    const parsed = moment.tz(d, "DD-MMM-YY hh:mm:ss A", "America/Chicago");
  
    if (!parsed.isValid()) {
      return null;
    }
    // to utc
    const utcTime = parsed.clone().utc();
  
    // to local
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //local time
    const localTime = utcTime.clone().tz(currentTimezone); // into browser
    return localTime.toDate();
  };

  const sortData = (des) => {
    if (!des) {
      if (sort === "down") {
        let sortedval = messages.sort(
          (a, b) => new Date(FormateDate(a.SendDate)) - new Date(FormateDate(b.SendDate))
        );
        setsort("up");
      } else {
        let sortedval = messages.sort(
          (a, b) => new Date(FormateDate(b.SendDate)) - new Date(FormateDate(a.SendDate))
        );
        setsort("down");
      }
    } else {
      let sortedval = messages.sort(
        (a, b) => new Date(FormateDate(b.SendDate)) - new Date(FormateDate(a.SendDate))
      );
      setsort("down");
    }
  };

  const updateRecords = (e) => {
    setRecords(e.target.value);
    handlePageChange(1);
  };
  const checkboxChange = () => {
    let deleteArr = [];
    let checks = $(".mdateAlignment .form-check-input");
    checks.each(function () {
      if ($(this).is(":checked")) {
        let delId = $(this).attr("data-id");
        deleteArr.push(delId);
      }
    });
    setMessagesidArr(deleteArr);
    // PO-213 : Removing handling of message delete button
    if (deleteArr.length > 0) {
      //$(".pnc-delete").show();
      $(".pnc-message-count-num").text(`(${deleteArr.length} Selected)`);
    } else {
      //$(".pnc-delete").hide();
      $(".pnc-message-count-num").text(`(0 Selected)`);
    }
    if (deleteArr.length === $(".form-check-input").not(".selectall").length) {
      $(".selectall").prop("checked", true);
    } else {
      $(".selectall").prop("checked", false);
    }
  };

  const renderMessages = () => {
    setloading(true);

    const URL = config.qa.node_url;

    let ProfileId = read_cookie("profileId");
    let apiUrl = `getProfileEmailMessages`;
    let requestData = {
      ProfileId,
    };
    let header = { programcode: "RTL_PNT", token: sessionStorage.accessToken };
    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        if (response.data) {
          setloading(false);
          let msgData = [...response.data];
          msgData.sort((a, b) => new Date(FormateDate(b.SendDate)) - new Date(FormateDate(a.SendDate)));
          setMessages(msgData);
          let numberOfRecords = response.data.length > 0 ? response.data.length : 1;
          setRecordsArr(inclusiveRange(10, numberOfRecords, 10));
          $(".selectall").prop("checked", false);
          checkboxChange();
        }
      })
      .catch(function (error) {
        console.error(error);
        setloading(false);
        // error;
      });
  };

  useEffect(() => {
    renderMessages();
    $(".selectall").click(function () {
      // PO-213 : Removing handling of message delete button
      if ($(this).is(":checked")) {
        $("input:checkbox").prop("checked", true);
        //$(".pnc-delete").show();
      } else {
        $("input:checkbox").prop("checked", false);
        //$(".pnc-delete").hide();
      }
      checkboxChange();
    });
  }, []);

  const setPopupState = () => {
    settogglePopup(!togglePopup);
    // setMessagesidArr([...messagesidArr]);
    // if(id){
    //     setMessagesidArr([id])
    // }else{
    //     setMessagesidArr([])
    // }
  };

  /* PO-213 : Removing delete message functionality.
  const deleteMessages = () => {
    const URL = config.qa.node_url;
    let apiUrl = `deleteMessages`;
    console.log(messagesidArr);
    // messagesidArr.forEach(msgid => {
    // let HarmonyMessageId = msgid;
    let requestData = {
      HarmonyMessageId: messagesidArr,
    };
    let header = { programcode: "RTL_PNT", token: sessionStorage.accessToken };

    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        if (response.data) {
          console.log(response.data);
          $(".selectall,.form-check-input").prop("checked", false);
        }
        renderMessages();
        setPopupState();
      })
      .catch(function (error) {
        console.error(error);
        // error;
        renderMessages();
        setPopupState();
      });
    // })
  }; */

  return (
    <div className="container">
      {loading ? <div className="loader"></div> : false}
      <div className="wrap-reward-messages">
        <div className="row">
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
            <div className="rewards-text">
              <h3 role="heading" aria-level="1">
                PNC Points | Messages
              </h3>
            </div>
            {/* <div className="rewards-sort text-right d-none d-lg-none d-md-block d-block">
              <span className="col-4 sort">
                <a className="sort-inactive" href="#">
                  <img
                    className="sort-img"
                    src="/images/icon-sort.svg"
                    alt="sort"
                  />
                  Sort
                </a>
                <a className="sort-expand" href="#">
                  <img
                    className="close-img"
                    src="/images/close-button.svg"
                    alt="close"
                  />{" "}
                  Close
                </a>
              </span>
            </div> */}
          </div>
        </div>
        <div className="row pnc-reward-block-wrap">
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
            {/* <div className="pnc-sort-mb">
              <p>SORT BY</p>
              <select
                id="filter-mb"
                className="form-control selectBox"
                aria-label="list-box"
                role="listbox"
              >
                <option value="10">Date</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <button type="submit" className="btn apply-filter">
                Apply Filter
              </button>
            </div> */}
            <div className="clearfix">
              <div className="inline-block float-left">
                <span className="all-select d-lg-none">
                  <a href="#">All</a>
                </span>
                <span className="message-count">
                  {messages.length} Messages
                </span>
                <span className="pnc-message-count-num"> </span>
              </div>

              {/* PO-213 : Removing delete button from messages listing.
                <span className="float-right pnc-delete" onClick={setPopupState}>
                <img src="/images/icon-trash.svg" alt="trash" />
                Delete
                </span>*/}
              <div className=" pagination-wrap">
              <div className="float-right view-per-page-sec">
                <div className="d-flex view-per-page-sec-inner">
                  <span>View</span>

                  <select
                    id="filter"
                    className="form-control selectBox"
                    onChange={updateRecords}
                    role="listbox"
                    aria-label="selectbox-pagination"
                  >
                    {recordsArr.slice(0,10).map((rec, index) => {
                      return (
                        <option value={rec} key={index}>
                          {rec}
                        </option>
                      );
                    })}
                  </select>
                  <span>Per page</span>
                </div>
              </div></div>
            </div>
            <div className="pnc-reward-wrapper">
              <div className="d-flex pnc-reward-message-table">
                {messages ? (
                  <div className="p-3 col-lg-4 check-box">
                    <div className="form-check d-none d-lg-block">
                      <input
                        type="checkbox"
                        className="form-check-input selectall"
                        id="electronic-program1"
                        value=""
                      />
                      <label
                        className="reward-message"
                        htmlFor="electronic-program1"
                      >
                        <span className="d-none">checkbox</span>
                      </label>
                    </div>
                    Sender
                  </div>
                ) : (
                  false
                )}
                <div className="p-3 col-lg-5">Subject </div>
                <div
                  className="p-3 col-lg-3"
                  onClick={() => {
                    sortData();
                  }}
                >
                  Time/Date{" "}
                  {sort === "up" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                {/* <div className="p-3 col-lg-1"></div> */}
              </div>
              {messages && messages.length > 0 ? (
                messages
                  .slice(startCount, startCount + records)
                  .map((message, index) => {
                    let isRead = message.IsRead === "Y" ? "" : "unread-message";
                    let type =
                      message.IsCampaignMessage === "true"
                        ? "campaign"
                        : "harmony";
                    let id =
                      message.IsCampaignMessage === "true"
                        ? message.CampaignMessageId
                        : message.HarmonyMessageId;
                    return (
                      <div
                        key={index}
                        className={`d-flex flex-wrap mAlernate ${isRead}`}
                        // href=""
                      >
                        <div className="p-3 col-6 col-md-4 col-lg-4 mdateAlignment">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              data-id={id}
                              id={"chk" + index}
                              onChange={checkboxChange}
                            />
                            <label
                              className="reward-message"
                              htmlFor={"chk" + index}
                            >
                              <span className="d-none">checkbox</span>
                            </label>
                          </div>

                          <Link to={`/MyProfile/Messaging/messageDetails/${type}/${id}`}>
                            {homeData.messageSenderTitle}
                          </Link>
                        </div>
                        <Link
                          to={`/MyProfile/Messaging/messageDetails/${type}/${id}`}
                          className="p-3 col-12 col-md-12 col-lg-5"
                        >
                          <span> {message.Description}</span>
                        </Link>
                        <Link
                          to={`/MyProfile/Messaging/messageDetails/${type}/${id}`}
                          className="p-3 col-6 col-md-8 col-lg-3 mcustomerService"
                        >
                          <span>
                            {/* {FormateDate(message.SendDate)} */}
                            <span className="d-none d-lg-inline">
                              <Moment format="hh:mm A">
                                {FormateDate(message.SendDate)}
                              </Moment>
                              {" "}
                            </span>
                            <Moment format="MM/DD/YYYY">
                              {FormateDate(message.SendDate)}
                            </Moment>
                          </span>
                        </Link>

                        {/* <span className="p-3 col-6 col-md-8 col-lg-1 mcustomerService">
                                            <span className="text-center delicon" onClick={()=>setPopupState(id)}>
                            <img src="/images/icon-trash.svg" alt="trash" />
                        </span>   
                                            </span> */}
                      </div>
                    );
                  })
              ) : (
                <a className="d-flex flex-wrap mAlernate" href="#">
                  <span className="p-3 col-6 col-md-4 col-lg-3 mdateAlignment">
                    {/* <div className="form-check">
                                          <input type="checkbox" className="form-check-input" id="electronic-program2" value="" />
                                          <label className="reward-message" htmlFor="electronic-program2"><span className="d-none">checkbox</span></label>
                                      </div> */}
                    {!loading ? "NO Records Found" : ""}
                  </span>
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="float-right view-per-page-sec">
          <div className="d-flex view-per-page-sec-inner">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={records}
              totalItemsCount={messages.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              hideDisabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardMessages;
